import Logo from '../../images/logo.png'
import {BiFlag} from 'react-icons/bi'
import {BsClockHistory,BsBookmark} from 'react-icons/bs'
import './Jobdescription.css'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { db} from "../../firebase";
import {HiOutlineLocationMarker} from 'react-icons/hi'
import moment from 'moment'
import { isRemote_data, type_data, country_data } from "../../data/util";
import useWindowDimensions from '../../hooks/windowwidth'
import { Helmet } from 'react-helmet-async';


const JobDescription = ()=>{
    let { id } = useParams();
    const { height, width } = useWindowDimensions();
    const [job,setJob]=useState(null);
    useEffect(()=>{
        if(id){
            db.collection('EaseJobs').doc(id).get().then((getSnapshot)=>{
                setJob(getSnapshot.data());
            })

        }
    },[id])
    
    return <div className="job-description-container">
        <Helmet>
        <title>{job?.position}</title>
        <meta name='description' content={`${job?.position} at ${job?.company?.name}`} />
        <meta name="keywords" content={`${job?.position}, ${job?.company?.name}, job at ${job?.location}`}></meta>
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://easejobs.in/job/${id}`}/>
        <meta property="og:title" content={`Easejobs | ${job?.position}`} />
        <meta property="og:description" content={`${job?.position} at ${job?.company?.name}`} />
        <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/easejobs-b6dac.appspot.com/o/CompanyLogo%2Flogo.png?alt=media&token=89797bdc-f53f-441b-bacc-20c41e8d9170" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://easejobs.in/job/${id}`} />
        <meta property="twitter:title" content={`Easejobs | ${job?.position}`} />
        <meta property="twitter:description" content={`${job?.position} at ${job?.company?.name}`}  />
        <meta property="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/easejobs-b6dac.appspot.com/o/CompanyLogo%2Flogo.png?alt=media&token=89797bdc-f53f-441b-bacc-20c41e8d9170" />


        </Helmet>
        <div className="job-description-up-container">
            <div className="job-header-container">
                <img src={job && job.company?.logo} className='jobdescription-logo'/>
                <h1 className="job-description-header">{job && job?.position}</h1>
                <div className='job-description-apply-container'>
                    <h3 className='job-card-second-header'>{job && job.company?.name}<span className='second-para'><HiOutlineLocationMarker className='job-card-icon'/>{job?.location}</span>{width>=600&& <span className='second-para'><BsClockHistory className='job-card-icon'/>{moment(new Date(job?.jobPostedAt.toDate())).fromNow()}</span>}</h3>
                    <div className='job-description-button-container'>
                        <a className='link' href={job?.link} target="_blank"><button className='apply-no-button'>Apply Now</button></a>
                        {false && <button className='bookmark-button'><BsBookmark className='bookmark-icon'/></button>}
                    </div>
                </div>
                <div className='feature-container'>
                    <div className='feature job-feature'>
                        <p className='feature-text'>{type_data[Number(job?.type)]?.value}</p>
                    </div>
                    <div className='feature type-feature'>
                        <p className='feature-text'>{isRemote_data[Number(job?.isRemote)]?.value}</p>
                    </div>
                    <div className='feature batch-feature'>
                        <p className='feature-text'>{job?.startBatch}-{job?.endBatch}</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="job-description-down-container" dangerouslySetInnerHTML={{__html: job?.description}}>
        </div>
    </div>
}

export default JobDescription;