import { useState } from 'react';
import './Ass.css'
import { storage,db } from '../../firebase';
import { useSelector ,useDispatch} from "react-redux";
import { setPannel } from '../../store/action-creators';
import { useParams } from 'react-router-dom';

const Ass = ()=>{
    const [vfile,setVfile]=useState(null);
    const [vlink,setVlink] = useState(null);
    const [link,setLink]=useState(null);
    const [loading,setIsLoading] = useState(false);
    let { id } = useParams();
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();
    const FileUpload = (event)=>{
        let file = event.target.files[0];
        console.log("Size===============>>>>>>>>>>>>>>",file.size);
        if(file.size<20971520){
            setVfile(file);
        }
    
    }
    const Submit = ()=>{
        console.log("----------->",link , vfile, loading);
        if(!user){
            dispatch(setPannel(true));
        }else{
            if(link && vfile && !loading){
                console.log(":hi");
                setIsLoading(true);
                var storageRef = storage.ref();
                 var fileName=`${Math.floor(Math.random()*100)+user.uid+Math.floor(Math.random()*100)}.${vfile.type.split("/")[1]}`;
    
            // // Create a reference to 'images/mountains.jpg'
             var videoPathRef = storageRef.child(`videos/${fileName}`);
    
            videoPathRef.put(vfile).then((snapshot) => {
                console.log('Uploaded a blob or file!',snapshot);
                snapshot.ref.getDownloadURL().then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    db.collection(id==1?"Frontend Assignment":"Backend Assignment").doc(user.uid).set({
                        link: link,
                        vlink: downloadURL,
                        email:user.email
                    }).then(()=>{
                        alert("Success");
                        setIsLoading(false);
                    })
                  });
              }).catch((err)=>{
                console.log(err);
                setIsLoading(false);
              })
    
            // While the file names are the same, the references point to different files
            }else{
                alert("Please Submit All Details");
            }
        }
    }
    return <div className="assignment-container">
        <h1 className='submit-header'>Automaite.ai {id==1 ?"Frontend":"Backend"} Assignment</h1>
        <p className='submit-para'>Last date for Submission 24/06/2023</p>
        <div className="link-container">
            <a className="assignment-link" target="blank" href={id==1?"https://docs.google.com/document/d/1IQ0hm8FKOGLDSePfcoUE3t4ZIIjB5Du1kxgXMFpzufE/edit":"https://docs.google.com/document/d/1DNiDOjqa8t48wzifW7WAVJOu_vDzg2A7cMt-PIg3PBQ/edit"}>Problem Statement</a>
        </div>
        <h2 className='input-lable'>Github Link</h2>
        <input className="submit-form-input" placeholder="Enter Github Link" onChange={(e)=>setLink(e.target.value)}/>
        <h2 className='input-lable'>Submission Video</h2>
        <input type="file" placeholder="Submit Video File" className="submit-file-input" accept="video/mp4,video/x-m4v,video/*" onChange={FileUpload}/>
        <button className="submit-submit-button" onClick={Submit}>Submit</button>
        {loading && <p>Loading.....</p>}

    </div>;
}
export default Ass;