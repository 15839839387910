import './Jobs.css'
import JobsCard from '../../components/JobsCard/JobsCard';
import useWindowDimensions from '../../hooks/windowwidth'
import { useState,useEffect } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { db, auth, provider } from "../../firebase";
import { useSearchParams , useNavigate,createSearchParams } from 'react-router-dom'

const Jobs= ()=>{
    const { height, width } = useWindowDimensions();
    const [jobs,setJobs]=useState([]);
    const [hasMore,setHasMore] = useState(true);
    const [lastKey,setLastKey] = useState(null);
    const [filtersType,setFiltersType]=useState(-1);
    let [searchParams, setSearchParams] = useSearchParams();


    //Filters
    const [isLive,setIsLive] = useState(false);
    const [batch,setBatch] = useState(-1);
    const [location,setLocation] = useState(-1);
    const navigate = useNavigate();
    // Update Local state Based on Search params
    useEffect(()=>{
        // Tab
        console.log(searchParams.get('tab'),filtersType);
        let data = -1;
        if(searchParams.get('tab')=='internship')data=0;
        if(searchParams.get('tab')=='job')data=1;
        if(searchParams.get('tab')=='other')data=2;
        if(searchParams.get('tab') && data!=filtersType){
            setFiltersType(data);
        }
    },[searchParams])
    // Fetch data First Time and when Url changes
    useEffect(()=>{
        console.log(filtersType)
        setHasMore(true);
        let ref=db.collection("EaseJobs");
        if(filtersType!=-1)ref=ref.where("type","==", filtersType);
        if(batch!=-1)ref=ref.where("batches","array-contains", batch);
        if(isLive) ref= ref.where('isLive',"==",true);
        if(location!=-1)  ref= ref.where('isRemote',"==",location);
        ref=ref.orderBy("jobPostedAt", "desc");

        ref.limit(10).get().then(({docs})=>{
            console.log(docs);
            setLastKey(docs[docs.length - 1]);
            const results=[];
            docs && docs.map(doc=>{
                const job=doc.data();
                results.push({id: doc.id,data:job});
            })
            setJobs(results);
        })
    },[filtersType,batch, isLive,location]);

    // Check any post is Left or not
    useEffect(()=>{
        if((!lastKey&&jobs.length>0)){
            setHasMore(false);
        }
    },[lastKey]);

    // Fetch More Data
    const fetchMoreData = ()=>{
        console.log("fetchMoreData",lastKey)
        let ref=db.collection("EaseJobs");
        if(filtersType!=-1)ref=ref.where("type","==", filtersType);
        if(batch!=-1)ref=ref.where("batches","array-contains", batch);
        if(isLive) ref= ref.where('isLive',"==",true);
        if(location!=-1)  ref= ref.where('isRemote',"==",location);
        ref=ref.orderBy("jobPostedAt","desc");
        ref.startAfter(lastKey).limit(10).get().then(({docs})=>{
            console.log(docs);
            setLastKey(docs[docs.length - 1]);
            const results=[];
            docs.map(doc=>{
                const job=doc.data();
                results.push({id: doc.id,data:job});
            })
            setJobs((oldJobs)=>[...oldJobs,...results]);
        })
    }
    // Update Params
    const updateParam=(k,v)=>{

        console.log(k,v);

        if(k=='tab'){
            if(searchParams.get('tab')!=v){
                const params = { tab: v};
                navigate({
                    pathname: '/jobs',
                    search: `?${createSearchParams(params)}`,
                  });
            }
        }
    }
    return<div className="jobs-container">
        <div className='jobs-left-container'>
            <nav className='filternav'>
                <button className={`filterbutton ${filtersType==-1?"active-filter":""}`} onClick={()=>updateParam('tab','all')}>All</button>
                <button className={`filterbutton ${filtersType==0?"active-filter":""}`} onClick={()=>updateParam('tab','internship')}>Internship</button>
                <button className={`filterbutton ${filtersType==1?"active-filter":""}`} onClick={()=>updateParam('tab','job')}>Fulltime</button>
                <button className={`filterbutton ${filtersType==2?"active-filter":""}`} onClick={()=>updateParam('tab','other')}>{width>=500 ? "Opensource Programs":"Other"}</button>
            </nav>
            <InfiniteScroll
          dataLength={jobs.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4 style={{textAlign:"center"}}>...</h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
        >
           {jobs.map((job)=>{
            return <JobsCard job={job.data} id={job.id}/>
           })}
            </InfiniteScroll>
        </div>
       {width>=900 && <div className='jobs-right-container'>
            <p className='filter-heading'>
                Filter
            </p>
            <div className='filter-input-container'>
                <input className='filter-input' type='checkbox' checked={isLive} onChange={()=>setIsLive(old=>!old)}/>
                <label className='filter-label'>Live only</label>
            </div>
            <p className='filter-devider-heading'>
                Batch
            </p>
            <div className='filter-input-container'>
                <input className='filter-input' type='checkbox' checked={batch==-1}  onChange={() => setBatch(-1)}/>
                <label className='filter-label'>All</label>
            </div>
            {
              [2021,2022,2023,2024,2025,2026,2027].map((year)=>{
                return (
                <div className='filter-input-container'>
                    <input className='filter-input' type='checkbox' checked={batch==year} onChange={() => setBatch(year)}/>
                    <label className='filter-label'>{year} Batch</label>
                </div>)
              })
            }
            <p className='filter-devider-heading'>
                Job Location Type
            </p>
            <div className='filter-input-container'>
                <input className='filter-input' type='checkbox' checked={location==-1} onChange={()=>setLocation(-1)}/>
                <label className='filter-label'>All</label>
            </div>
            <div className='filter-input-container'>
                <input className='filter-input' type='checkbox'checked={location==0} onChange={()=>setLocation(0)}/>
                <label className='filter-label'>Remote</label>
            </div>
            <div className='filter-input-container'>
                <input className='filter-input' type='checkbox' checked={location==1} onChange={()=>setLocation(1)}/>
                <label className='filter-label'>Onsite</label>
            </div>
            <div className='filter-input-container'>
                <input className='filter-input' type='checkbox' checked={location==2} onChange={()=>setLocation(2)}/>
                <label className='filter-label'>Hybrid</label>
            </div>
        </div>}
    </div>;
}

export default Jobs;