import './Home.css'
import HeroImg from '../../images/HeroImage.png'
import {BiSearchAlt} from 'react-icons/bi'
import FeaturedJobs from '../../components/FeaturedJobs/FeaturedJobs'
import useWindowDimensions from '../../hooks/windowwidth'
import { Link } from 'react-router-dom'

const Home = ()=>{
    const { height, width } = useWindowDimensions();

    return(
    <main>
        <header className='hero-container'>
            <div className="hero-left-container">
                <h1 className="hero-heading">Get your dream job here</h1>
                <h2 className="hero-para">Daily job updates, professional portfolio, resources that help you to crack your dream company and many more</h2>
                <div className='hero-btn-container'>
                    <Link to="/jobs" className='link'><button className='register-button'><BiSearchAlt className='job-icon' size={16}/>Find Jobs</button></Link>
                    {false && <button className='find-jobs-button'>Register</button>}
                </div>
            </div>
            {(width>=1000)&&<div className="hero-right-container">
                <img src={HeroImg} className='hero-image'/>
            </div>}
        </header>
        {/* <div className='featuredjobs-container'>
            <h2 className='featured-jobs-heading'>Featured Jobs Only for you</h2>
            <p>Discover all Job and Internship Openinggs </p>
            <FeaturedJobs/>
        </div> */}
    </main>);
}
export default Home;