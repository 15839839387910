import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAFWNMZg6F-ibOWI8Y78UjALymU-hugtTE",
  authDomain: "easejobs-b6dac.firebaseapp.com",
  projectId: "easejobs-b6dac",
  storageBucket: "easejobs-b6dac.appspot.com",
  messagingSenderId: "1089742188594",
  appId: "1:1089742188594:web:201802048a93fc4e570c2b",
  measurementId: "G-RXY18DRE2B",
};

var app = firebase.initializeApp(firebaseConfig);
var db = app.firestore();
var auth = app.auth();
var storage = app.storage();
var provider = new firebase.auth.GoogleAuthProvider();
export { db, storage, auth, firebase, provider };