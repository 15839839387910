const isRemote_data = [
    {
      id: 0,
      value: "Remote",
    },
    {
      id: 1,
      value: "On Site",
    },
    {
      id: 2,
      value: "Hybrid",
    },
  ];
  
  const type_data = [
    {
        id: 0,
        value: "Internship",
    },
    {
      id: 1,
      value: "Full Time",
    },
    {
      id: 2,
      value: "Other",
    }
  ];
  
  const country_data = [
    {
      id: 0,
      value: "India",
      code: "IN",
    },
    {
      id: 1,
      value: "USA",
      code: "US",
    },
    {
      id: 2,
      value: "UK",
      code: "GB",
    },
  ];
  
  const batch_data = [2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026];
  
  export { isRemote_data, type_data, country_data, batch_data };