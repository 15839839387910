import './JobsCard.css'
import Logo from '../../images/logo.png'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {BsClockHistory,BsBookmark} from 'react-icons/bs'
import { isRemote_data, type_data, country_data } from "../../data/util";
import {Link} from 'react-router-dom'
import moment from 'moment';
import useWindowDimensions from '../../hooks/windowwidth'


const JobsCard= ({job,id})=>{
    const { height, width } = useWindowDimensions();
    return <div className='jobs-card-container'>
            <img src={job &&job.company ? job.company.logo: ""} alt="logo" className='logo-image'/>
            <div className='job-card-right'> 
                <div className='job-card-header-container'>
                <Link className='link' to={`/job/${id}`}><h2 className='job-card-header'>{job ? job.position?.substring(0, 100): ""}</h2></Link>
                {false && <button className='bookmark-button'><BsBookmark className='bookmark-icon'/></button>}
                </div>
                <h3 className='job-card-second-header'>{job && job.company ? job.company.name?.substring(0, 20):""}<span className='second-para'><HiOutlineLocationMarker className='job-card-icon'/>{job?.location?.substring(0, 15)}</span>{width>=600 &&<span className='second-para'> <BsClockHistory className='job-card-icon'/> {moment(new Date(job?.jobPostedAt.toDate())).fromNow()}</span>}</h3>
                <div className='feature-container'>
                    <div className='feature job-feature'>
                        {job &&<p className='feature-text'> <p>{type_data[Number(job?.type)]?.value}</p></p>}
                    </div>
                    <div className='feature type-feature'>
                        {job&& <p className='feature-text'>{isRemote_data[Number(job?.isRemote)]?.value}</p>}
                    </div>
                    <div className='feature batch-feature'>
                        <p className='feature-text'>{job?.startBatch}-{job?.endBatch}</p>
                    </div>
                </div>
            </div>
        </div>;
}
export default JobsCard;