import logo from './logo.svg';
import './App.css';
import { auth, firebase, provider } from "./firebase";
import { useDispatch, useSelector } from "react-redux";
import { setUser,setPannel } from "./store/action-creators";
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

// Pages

import Home from './Pages/Home/Home'
import Jobs from './Pages/Jobs/Jobs';
import JobDescription from './Pages/JobDescription/Jobdescription'
import Signup from './components/Signup/Signup';
import Blogs from './Pages/Blogs/Blogs';
import SingleBlog from './Pages/SingleBlog/SingleBlog'
import EditProfile from './components/EditProfile.js/EditProfile';
import Ass from './Pages/Ass/Ass'
// Components
import NavBar from './components/Navbar/Navbar';


function App() {
  const dispatch = useDispatch();
  firebase.auth().onAuthStateChanged((user) => {
    console.log("User============>")
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      console.log(user);
      dispatch(setUser(user));
      dispatch(setPannel(false))
      // ...
    } else {
      // User is signed out
      // ...
      dispatch(setUser(null));
    }
  });
  return (
    <HelmetProvider>
    <div className="App">
      <BrowserRouter>
        <NavBar/>
        <Signup/>
        <EditProfile/>
        <Routes>
            <Route
              path={"/"}
              element={
                  <Home />
              }
            />
            <Route
              path={"/jobs"}
              element={
                  <Jobs />
              }
            />
            <Route
              path={"/job/:id"}
              element={
                <JobDescription/>
              }
            />
              <Route
              path={"/blogs"}
              element={
                <Blogs/>
              }
            />
              <Route
              path={"/blog/:id"}
              element={
                <SingleBlog/>
              }
            />
            <Route
            path={"/assignment/automatie/:id"}
            element={<Ass/>}
            />
          </Routes>
      </BrowserRouter>
    </div>
    </HelmetProvider>
  );
}

export default App;
