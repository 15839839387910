import './BlogCard.css'
import {BsClockHistory} from 'react-icons/bs'
import moment from 'moment'

const BlogCard = ({data,id})=>{
    return<div className="blog-card-container">
        <div className="blog-card-left">
            <h2 className='blog-card-header'>{data?.title?.substring(0,100)}</h2>
            <p className='blog-card-para'>{data?.miniDescription?.substring(0,180)}</p>
            <div className='tags-container'>{data?.tags?.map(tag=><span className='blog-card-tags'>{tag}</span>)}</div>
            <p className='blog-card-para'><BsClockHistory className='job-card-icon'/><span>{moment(new Date(data?.blogPostedAt.toDate())).fromNow()}</span></p>
        </div>
        <div className="blog-card-right">
            <img className="blog-card-image" src={data?.coverImage}/>
        </div>
    </div>
}
export default BlogCard;