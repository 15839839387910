import { AuthAction, PannelAction } from "./action-types";
const intialState = {
  user: null
};

export const AuthReducer = (state = intialState, { type, payload }) => {
  console.log(payload);
  switch (type) {
    case AuthAction.SET_USER:
      return {
        ...state,
        user: payload,
      };
    default:
      return state;
  }
};

const initialState2={
  isSignupOpen: false
}

export const PannelReducer = (state = initialState2, { type, payload }) => {
  console.log(payload);
  switch (type) {
    case PannelAction.SET_PANNEL:
      return {
        ...state,
        isSignupOpen: payload,
      };
    default:
      return state;
  }
};