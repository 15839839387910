import './Blogs.css'
import BlogCard from '../../components/BlogCard/BlogCard';
import { useEffect, useState } from 'react';
import {db} from '../../firebase'
const Blogs = ()=>{

    const [blogs,setBlogs] = useState([]);

    useEffect(()=>{
        db.collection('EaseBlogs').get().then(({docs})=>{
            let results = [];
            docs.map((doc)=>{
                results.push({id: doc.id, data: doc.data()});
            })
            setBlogs(results);
        })
    },[])

    return (<main className="blog-container">
        <div className="blog-left-container">
            {blogs.map((blog)=>{
                return<BlogCard
                    data={blog.data}
                    id={blog.id}
                />
            })}
        </div>
        <div className="blog-right-container">
            <h3 className='subscribe-header'>Subscribe</h3>
            <p className='subscribe-para'>get weekly updates on Interview preparation, DSA and Development Roadmap, Interview experiences etc.</p>
            <form className='subscribe-input-container' 
               action='https://7d0617c5.sibforms.com/serve/MUIFAO-ivcU3C9ujJZTK_xA3FbGkYqDX-ZXjdofnVgDvpHaeXpDFO09lPFn4l8IPwEkh0mKun5icNsq2BgDwOrts7toOUJpHx9VBpUW4zBRfEizAT8VJZ_K5JCgMvzEH4-lwZJR2_4ldZVQ5AMxzchUOSNv0Nn7J4GVW0gnPjbSsYwe5HSCP6H3wPTCK5Obs7AhnM46rFXzlaWfB'
                method='post'
                noValidate
                onSubmit={()=>window.open("https://7d0617c5.sibforms.com/serve/MUIFAO-ivcU3C9ujJZTK_xA3FbGkYqDX-ZXjdofnVgDvpHaeXpDFO09lPFn4l8IPwEkh0mKun5icNsq2BgDwOrts7toOUJpHx9VBpUW4zBRfEizAT8VJZ_K5JCgMvzEH4-lwZJR2_4ldZVQ5AMxzchUOSNv0Nn7J4GVW0gnPjbSsYwe5HSCP6H3wPTCK5Obs7AhnM46rFXzlaWfB", "popupwindow", "scrollbars=yes,width=550,height=520")}
                target='popupwindow'
                name="sib-subscribe-form"
            >
                <input type="submit"  className='subscribe-button' value="Subscribe"/>
            </form>
        </div>
    </main>);
}
export default Blogs;