import { AuthAction,PannelAction } from "./action-types";

export const setUser = (user) => {
  return {
    type: AuthAction.SET_USER,
    payload: user,
  };
};
export const setPannel = (isSignupOpen)=>{
  return {
    type: PannelAction.SET_PANNEL,
    payload: isSignupOpen
  }
}