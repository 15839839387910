import './Navbar.css'
import { Link ,useLocation } from "react-router-dom";
import Logo from "../../images/logo.png"
import useWindowDimensions from '../../hooks/windowwidth'
import {setPannel} from '../../store/action-creators'
// icons
import {AiFillHome} from 'react-icons/ai'
import {FaCompass,FaBloggerB} from 'react-icons/fa'
import {MdAccountCircle} from 'react-icons/md'
import { useSelector ,useDispatch} from "react-redux";
import { db, auth, provider } from "../../firebase";
import { useRef } from 'react';

const NavBar = ()=>{
    const { height, width } = useWindowDimensions();
    const location = useLocation();
    const { hash, pathname, search } = location;
    const user = useSelector((state) => state.user.user);
    const dispatch = useDispatch();

    const Logout=()=>{
        auth.signOut().then(() => {
            // Sign-out successful.
            console.log("Signout");
          }).catch((error) => {
            // An error happened.
          });
    }

    console.log("pathname==================>" ,pathname,"sea",search)

    if(width>=880)
    return<nav className="navbar-container">
        <div className="nabar-logo-container">
            <img src={Logo} className='navbar-logo' alt="Easejobs Logo" title='Easejobs Logo'/>
        </div>
        <ul className="navbar-links-container">
            <li ><Link to="/" className={`nav-bar-links ${pathname=='/'?'active':''}`} >Home</Link></li>
            <li ><Link to="/jobs?tab=job" className={`nav-bar-links ${pathname=='/jobs'&& search=='?tab=job' ?'active':''}`}>Browse Jobs</Link></li>
            <li ><Link to="/jobs?tab=internship" className={`nav-bar-links ${pathname=='/jobs' && search=='?tab=internship'?'active':''}`}>Browse Internships</Link></li>
            {false && <li ><Link to="/blogs" className={`nav-bar-links ${pathname=='/blogs'?'active':''}`}>Blog</Link></li>}
        </ul>
        <div className='navbar-mid'></div>
        <div className='navbar-auth-container'>
            { !user && <button className='nav-buttons' onClick={()=> dispatch(setPannel(true))}>Signin/Signup</button>}
            {
             user&&<>
               {false && <Link to="/"><button className='nav-buttons'>My Profile</button></Link>}
                <span to="/" className='nav-bar-links' onClick={Logout}><p>Logout</p></span>
             </>
            }
        </div>
    </nav>;
    else return <>
        <nav className='bottom-nav-container'>
         <div className="nabar-logo-container">
            <img src={Logo} className='navbar-logo' alt="Easejobs Logo" title='Easejobs Logo'/>
         </div>
        </nav>
        <nav className='bottom-nav-container-2'>
        <Link to="/" className={` ${pathname=='/'?'active-b':''}`} ><div className='bottom-nav-button-container'><AiFillHome size={22} className='bottom-nav-icon'/></div></Link>
        <Link to="/jobs" className={` ${pathname=='/jobs'?'active-b':''}`}><div className='bottom-nav-button-container'><FaCompass size={22} className='bottom-nav-icon'/></div></Link>
        {false &&< >
            <div className='bottom-nav-button-container'><FaBloggerB size={22} className='bottom-nav-icon'/></div>
            </>}
            <div className='bottom-nav-button-container'><MdAccountCircle size={22} className='bottom-nav-icon'/></div>
        </nav>
        </>
}
export default NavBar