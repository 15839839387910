import { useState } from "react";
import SlidingPanel from "react-sliding-side-panel"
import BasicInformation from "./BasicInformation/BasicInformation";
import './EditProfile.css'
const EditProfile= ()=>{
    const [isEditProfileOpen,setIsEditProfileOpen] = useState(false);
    return(
    <SlidingPanel
        type={'right'}
        isOpen={isEditProfileOpen}
        size={100}
    >
        <div className='pannel-container'>
            <div className='left-side-pannel'></div>
            <div className='pannel-content'>
                <BasicInformation/>
            </div>
        </div>
   </SlidingPanel>);
}
export default EditProfile;