import { useEffect, useState } from 'react';
import SlidingPanel from 'react-sliding-side-panel';
import 'react-sliding-side-panel/lib/index.css';
import './Signup.css'
import {AiOutlineClose} from 'react-icons/ai'
import {Link} from 'react-router-dom';
import Google from '../../images/Google2.png'
import { getUserId } from '../../utils/authutils';

import { db, auth, provider } from "../../firebase";
import { useSelector,useDispatch } from "react-redux";
import {setPannel} from '../../store/action-creators'
import validator from 'validator';


const Signup=()=>{
    const [isLogin,setIsLogin]=useState(false);
    const [isLoading,setIsLoading]= useState(false);
    const user = useSelector((state) => state.user.user);
    const isSignupOpen = useSelector((state)=>state.isSignupOpen.isSignupOpen)
    const dispatch = useDispatch();
    //false-->Signup
    //true-->Login

    const [email,setEmail]=useState("");
    const [password,setPassword]=useState("");
    const [cpassword,setCPassword]=useState("");

    console.log("User======================>>>>>>>>", user);

    const isValid= (email,password,cpassword)=>{
        console.log((email))
        if (!validator.isEmail(email)) {
            console.log('Invalid Email Address');
        }
        if(password.length >6){
            console.log("Password Length Should be greater than 6");
        }if(password==cpassword){
            console.log("password and conform password should match");
        }
        return true;
    }

    const signInWithGoogle =  () => {
        setIsLoading(true);
        auth.signInWithPopup(provider).then((res) => {
            const user = res.user;
            db.collection("users").doc(user.uid).get().then((docSnapshot)=>{
                if (!docSnapshot.exists) {
                    db.collection("users").doc(user.uid).set({
                        uid: user.uid,
                        name: user.displayName,
                        authProvider: "google",
                        email: user.email,
                        userid:getUserId(user.email)
                    }).then(()=>{
                        setIsLoading(false);
                    })
                }
                else{
                    setIsLoading(false);
                }
            })
          }).catch((error) => {
            console.log(error.message);
            setIsLoading(false);
          })
      };

      const Register = ()=>{
        if(!isLogin && isValid(email,password,cpassword)){
            console.log("hi");
            auth.createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in 
                var user = userCredential.user;
                // ...
                db.collection("users").doc(user.uid).set({
                    uid: user.uid,
                    authProvider: "local",
                    email: email,
                    userid:getUserId(email)
                }).then(()=>{
                    setIsLoading(false);
                }).catch((error)=>{
                    console.log("Erro2",error)
                })
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                console.log("Errpr",error, errorMessage,errorCode);
                // ..
            });
        } 
         else{
            auth.signInWithEmailAndPassword(email, password)
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
            });
        }
      }

    return <div>
     <SlidingPanel
                type={'right'}
                isOpen={isSignupOpen}
                size={100}
        >
      <div className='pannel-container'>
        <div className='left-side-pannel'></div>
        <div className='pannel-content'>
            <button onClick={() => dispatch(setPannel(false))} className='pannel-close-button'>
                <AiOutlineClose className='close-icon'/>
            </button>
            <p className='signup-header'>{isLogin?"Login":"Sign up"}</p>
            {false && <><p className='signup-sub-header'>or <span className='heilight' onClick={()=>setIsLogin(prev=>!prev)}>{isLogin?"Create your account":"login to your account"} </span></p>
            <div className='input-containers'>
                <input className='input email-input' type='email' placeholder='Email: Eg. youremail@gmail.com' onChange={(e)=>setEmail(e.target.value)}/>
                <input className='input password-inout' type='password' placeholder='Password: At least 6 characters' onChange={(e)=>setPassword(e.target.value)}/>
                {!isLogin && <input className='input confirm-password-inout' type='password' placeholder="Confirm Password" onChange={(e)=>setCPassword(e.target.value)}/>}
                {false && isLogin &&<p className='forget-password'>Forget Password?</p>}
            </div>

            <button className='submit-button' onClick={Register}>{isLogin?"Login":"Register"}</button>
            
            <div className='or-container'>
                <div className='or-devider'></div>
                <p className='or-text'>Or</p>
                <div className='or-devider'></div>
            </div>
            </>
            }
            <button className='Google-signin-button' onClick={signInWithGoogle}>
                <img src={Google} className='google-image' />Coninue with Google</button>
        </div>
      </div>
    </SlidingPanel>
  </div>;
}
export default Signup;