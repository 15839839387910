const BasicInformation = ()=>{
    return (
        <>
            <h2 className="pannel-header">Basic Details</h2>
            <div className="profile-input-container">
                <label className="profile-lable">Name</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Primary email</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Graduation Year</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Location</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Profile Description</label>
                <input className="profile-input"/>
            </div>
            <h3 className="profile-devider">Social Links</h3>
            <div className="profile-input-container">
                <label className="profile-lable">Linkedin Profile</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Github Profile</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Twitter Profile</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Portfolio</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Youtube</label>
                <input className="profile-input"/>
            </div>
            <div className="profile-input-container">
                <label className="profile-lable">Other</label>
                <input className="profile-input"/>
            </div>
            <div className="save-button-container">
            <button className="save-button">
                Save
            </button>
            </div>
    </>
    
    );
}

export default BasicInformation;